import React from 'react';
import styled from 'styled-components';
import logo from '../assets/logo.svg';

const Img = styled.img`
  height: 45px;
`;

const Logo = () => {
  return <Img src={logo} />;
};

export default Logo;
