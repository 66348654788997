import styled from "styled-components";
import media from "./media";
import { Flex } from "components/shared/Flex";

export const MonitorWrapper = styled(Flex)<{ isSettingsOpen: boolean }>`
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
  z-index: ${({ isSettingsOpen }) => isSettingsOpen && '-1'};
  ${media.tablet`
    width: fit-content;
    justify-content: center;
  `}
`;

export const MeasurementContentWrapper = styled(Flex)<{ isMobile: boolean }>`

padding: 2.6em;
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
  
 
 
  ${media.mobile`
    margin: 40px 0 60px 0;
  `}
`;

export const VideoAndStatsWrapper = styled(Flex)<{ isMobile: boolean }>`
  position: relative;
  justify-content: center;
  width: 100%;
  height: ${({ isMobile }) => isMobile && '100%'};
  ${media.tablet`
    width: 812px;
    height: 609px;
  `} ${media.wide`
    width: 1016px;
    height: 762px;
  `};
`;

export const VideoWrapper = styled.div`
position:fixed;
  width: 100vw;
  height: 100vh;
  z-index: -5;
  @media (max-width: 320px) {
    top:10%
    // positon:relative;
    z-index: -4;
  }
  
`;

export const Img = styled.img<{ isDesktop: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;

  z-index: 1;
  object-fit: ${({ isDesktop }) => (isDesktop ? 'contain' : 'cover')};
    object-fit: cover;
  
  @media (max-width: 425px) {
    
  }
`;

// const Video = styled.video<{ isMobile: boolean }>`
//   width: 100%;
//   height: 100%;
//   object-fit: ${() => (isIos() ? 'unset' : isMobile() ? 'cover' : 'contain')};
//   ${mirror}
// `;

export const ButtonWrapper = styled(Flex)`
  flex: 2;
  z-index: 3;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: -30px;
  ${media.mobile`
    margin-top: 50px;
  `}
  ${media.tablet`
  padding: 0;
  height: auto;
  width: auto;
  position: absolute;
  right: 0;
  bottom: 42%;
  margin-right: 60px;
`}
`;

export const ButtomTimerWrapper = styled(Flex)`
position: abosolute;
rigth:0%;
top:50%;
  ${media.tablet`
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    height: 30px;
    display: flex;
  `}
`;

export const InfoBarWrapper = styled.div`
  height: 25px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  ${media.mobile`
    flex: 0.45;
  `}
`;

export const Main = styled.main`
padding: 2.6em;
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
`;

export const Video = styled.video`

width: 100%;
height: 100vh;
z-index:-1;
object-fit: cover;
transform: scaleX(-1);
`;

export const Button = styled.button`
  background-color: #007bff; /* Primary button color */
  color: #fff; /* Button text color */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #0069d9; /* Button hover effect */
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;

`;

export const CardCount = styled.div`

width: 240px;
height: 180px;
background-color: rgb(237, 237, 237);
border-radius: 10px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
position: fixed;
top: 40%;
left: 1%;


width: 240px;
height: 180px;

border-radius: 10px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
position: absolute;
left: 1%;
z-index:80;
@media (max-width: 1000px) {
  z-index: 1;
  width: 100%;
  height: 65px;
  position:relative;
  top:0;
  position: absolute;
  top:0%;
  left: 0;
  border-radius: 0;
  
  
}
`;

// const Timer = styled.div`
// margin: 0.5em auto;
// background-color:#3E9647 ;
// color: #FFFF;
// padding: 0.3em;
// border: none;
// width: 60%;
// border-radius: 7px;
// cursor: pointer;
// text-align: center;
// font-size: 2em;
// `;

export const ErrorMessage = styled.div`
width: 80%;
height: 8.6vh;
display: flex;
justify-content: center;
align-items: center;
font-weight: 600;
flex-direction: column;
border-radius: 5px;
color: #EDEDED;
`;

export const CardInfo = styled.div`

width: 250px;
height: 10px;

border-radius: 10px;
display: flex;
justify-content: start;
align-items: center;

position: fixed;
top:0%;
right: 4%;
@media (max-width: 1000px) {
  
  width: 100vw;
  height: 30px;
  // position: absolute;
  
  
  left: 0;
  position:relative;
  border-radius: 0;
 
}
`;

export const CardHead = styled.div`
    margin-top: 0.6em;
    width: 100%;
    display: flex;
`;


export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; /* Ajusta el ancho del contenedor */
  height: 50px; /* Ajusta la altura del contenedor */
  border-radius: 50%; /* Borde circular */
  background-color: #fff; /* Color de fondo del contenedor */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra sutil */
`;

export const LogoImage = styled.img`
  width: 80%; /* Ajusta el tamaño de la imagen */
  height: 80%; /* Ajusta el tamaño de la imagen */
  
`;

export const Subtitle = styled.h2`
  font-size: 1.2rem; /* Adjust subtitle size */
  margin: 0; /* Remove default margin */
`;

export const MessageList = styled.div`
      width: 100%;
    height: 100vh;
    border-radius: 10px;
    margin-top: 1.5em;
`;

export const Message = styled.p`
text-align: justify;
align-items: center;
margin: 0.25em;
margin-left: 0.95em;
font-size: 0.9em;
font-family: Roboto;
`;



export const MainVideo = styled.div`

position:relative;
width:100vw;
height:100vh;
   display:flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;

       display: flex; /* Make the main container a flexbox */
    flex-direction: column; /* Arrange elements vertically */
    align-items: center; /* Horizontally center elements if needed */
   
`;


export const Container = styled(Flex)<{ isSettingsOpen: boolean }>`
  height: 100vh;
  width: 100vw;
  position: relative;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow:hidden;
  background-color: ${({ isSettingsOpen }) =>
    isSettingsOpen && 'rgba(0, 0, 0, 0.5)'};
`;