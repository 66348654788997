import React from 'react';
import styled from 'styled-components';
import { Flex } from '../shared/Flex';

const Wrapper = styled(Flex)`
  margin:3px auto;
  height: 0px;
  padding:2px 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: red;
  padding: 0px;
  box-sizing: border-box;
`;

const Message = styled.div`
 
  font-size: 14px;
  color: black;
  text-align: center;
`;

const InfoAlert = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <Wrapper>
      <Message>{message}</Message>
    </Wrapper>
  );
};
export default InfoAlert;
