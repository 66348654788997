import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Card } from './Card';
import { useLocation } from 'react-router-dom';
import { Flex } from './shared/Flex';
import SALA_ESPERA from '../assets/SALA_ESPERA.jpg';

const Container = styled(Flex)`
  height: 100vh;
  width: 100vw;
  position: relative;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow:hidden;
  background: url(${SALA_ESPERA});
  background-color: rgb(106 0 0 / 40%);
  background-blend-mode: soft-light;
  background-size: cover;
`;

export const Select = () => {

  const location = useLocation();
  const consultation = decodeURIComponent(new URLSearchParams(location.search).get('consultation'));
  const authorization = decodeURIComponent(new URLSearchParams(location.search).get('authorization'));

  // const MyContext = React.createContext({ 'consultation' : consultation, 'authorization' : authorization });

  useEffect(() => {

    console.log('Token de Acceso', consultation);
    console.log('Token de Authorizacion', authorization);

    localStorage.setItem('session', String(authorization));
    localStorage.setItem('consultation', String(consultation));

  }, []);

  return (
    <Container>
      {/* <MyContext.Provider value={{ 'consultation' : consultation, 'authorization' : authorization }}></MyContext.Provider> */}
      <Card></Card>
    </Container>
  );
};