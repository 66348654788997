import styled from "styled-components";

export const CardLogo = styled.div`
background-color: #EDEDED;
width: 220px;
height: 300px;
padding: 1.2em;
padding-top: 3em;
border-radius: 10px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
position:fixed;
top:30%;
z-index:5000;
@media (max-width: 1000px) {
  position:fixed;
  top: 20%;
}


`;

// Container card component with styles
export const ContainerCard = styled.div`
background:red;
padding: 2.6em;
padding-top:0em
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
@media (max-width: 1000px) {
  height: 10vh;
  position:fixed;
  top: 0;
}

`;

export const Logo = styled.div`
width: 100%;
text-align: center; /* Center logo horizontally */
`;

export const LogoImage = styled.img`
width: 40%;
height: auto; /* Maintain aspect ratio */
margin-bottom: 0em; /* Add space below logo */
fill: red;
`;

// Button component with styles
export const ButtonSuccess = styled.button`
background-color: #3E9647;
color: #fff;
padding: 0.6em;
border: none;
width: 90%;
border-radius: 15px;
cursor: pointer;
`;

// Subtitle component with styles
export const Subtitle = styled.p`
font-size: 0.8em;
margin: 6px auto;
font-family: Arial, Helvetica, sans-serif;
`;

// Sub-message component with styles
export const SubMessage = styled.div`
background-color: #fff;
width: 80%;
height: 80px;
border-radius: 10px;
margin-bottom: 0.7em;
margin-top:0.2em;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;

export const SubMessageParagraph = styled.p`
text-align: center;
font-size: 0.6em;
margin-bottom:0.4em;
font-family: Roboto, Helvetica, sans-serif;
`;

export const MessageParagraph = styled.p`
text-align: center;
font-size: 0.6em;
margin-bottom:0.6em;
font-family: Roboto, Helvetica, sans-serif;
`;

// Button return component with styles
export const ButtonReturn = styled.button`
background-color: transparent;
border: none;
margin-top: 0.5em;
color: red;
font-weight: 600;
`;