import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useTimer } from '../hooks';
import media from '../style/media';
import { useMediaPredicate } from 'react-media-hook';
import StartButton from './StartButton';
import { InfoAlert } from './alert';
import Logo from './Logo';

const Value = styled.div`
  font-size: 16px;
  color: #01061b;
  ${media.tablet`
    color: #0653f4;
    font-size: 24px;
 `}
`;

const Card = styled.div`

  
  width: 220px;
  height: 30vh;
  padding: 1.2em;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1000px) {
    width: 100%;
    height: 2em;
    border-radius: 5px;
    flex-direction: row;
    justify-content: space-around
  }
`;

const Contador = styled.div`
  background-color: #3E9647;
  color: #fff;
  font-size: 2em;
  width: 100%;
  text-align: center;
  padding: 3px 0;
  border-radius: 20px;
  margin-bottom:4px;
  @media (max-width: 1000px) {
    font-size: 1.2em;
    padding: 1px 0;
    width: 60%;
    height: 2em;
    margin-bottom:7px;
  }
`;

const Container = styled.div`
  height: 10vh;
  margin:2px auto;
  background-color: #ff0000;
  width: 100%;
  border-radius: 15px;
  text-align: center;
  line-height: -1px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top:4px;
  @media (max-width: 1000px) {
    width: 60%;
    border-radius: 5px;
  }
`;

const ContainerMessage = styled.div`
  height: 2vh;
  width: 100%;
  text-align: center;
  line-height: -1px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top:3px;
  @media (max-width: 1000px) {
    width: 60%;
    height: 4em;
    border-radius: 5px;
  }
`;

const Message = styled.p`
  font-family: Roboto, Arial, Helvetica, sans-serif; /* Fallback fonts */
  color: #fff;
  font-size: 0.6em;
  line-height: 120%;
  display: flex;
  justify-content: center;
  align-content: center;
  @media (max-width: 1000px) {
  font-size: 0.7em;
  }
`;

const LogoPage = styled.div`
  display : flex;
  align-items: center;
  height: 60px;
  width: auto;
  background: rgb(237, 237, 237);
  padding: 0px 6px;
  border-radius: 12px;
  justify-content: center;
  object-fit: contain;

  @media (max-width: 1000px) {
    height: 50px;
  }
`;


const Timer = ({ started, durationSeconds, isLoading, onClick, isMeasuring, errorMessage, messageLicense, hideLogo }) => {

  const notLogo = hideLogo
  const isMediaTablet = useMediaPredicate('(min-width: 1000px)');
  const seconds = useTimer(started, durationSeconds);
  const formatMinutes = useCallback(
    (seconds) => ('0' + Math.floor(seconds / 60)).slice(-2),
    [seconds],
  );
  const formatSeconds = useCallback(
    (seconds) => ('0' + (seconds % 60)).slice(-2),
    [seconds],
  );
  return (
    <Card>
      <LogoPage style={notLogo ? { 'display': 'none' } : {}}>
        <Logo />
      </LogoPage>
      {
        !isMeasuring ? <StartButton
          isLoading={isLoading}
          isMeasuring={isMeasuring}
          onClick={onClick}
          messageLicense={messageLicense}
        /> : <>
          <Contador> {!isMediaTablet}
            {formatMinutes(seconds)}:{formatSeconds(seconds)}</Contador>

          {
            started && <ContainerMessage>
              <InfoAlert message={errorMessage} />
            </ContainerMessage>
          }

          <Container>
            <Message>NO TE MUEVAS NI HABLES </Message>
            <Message>DURANTE EL TIEMPO DEL SCAN</Message>
          </Container>
        </>
      }
    </Card>
  );
};

export default Timer;