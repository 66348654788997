import React from 'react';
import styled from 'styled-components';
import { FlexSpace } from './shared/FlexSpace';

const Box = styled(FlexSpace)`
  flex-direction: column;
  justify-content: center;
  gap:2em;
  align-items: start;
  height: 10px;
  margin-left:1.3em;
  margin-top:0.9em;
display:flex;
width:70%;
  @media (max-width: 1000px) {
    width:30%;
    display:flex;
    justify-content:start;
  }
  @media (max-width: 585px) {
    width:40%;
    display:flex;
    gap:1.2em;
    margin-top:0.4em;
    justify-content:start;
  }
`;

const Title = styled.div`
  display: flex;
 
  justify-content: center;
 
  color: #01061b;
  font-size: 0.9em;
  font-weight: 700;
  @media (max-width: 1000px) {
   
  }

  @media (max-width: 420px) {
    font-size: 10px;
    justify-content: start;
  }

  @media (max-width: 320px) {
    font-size: 7px;
    justify-content: start;
  }

`;

const Value = styled.div`
  font-size: 0.9em;
  color: #01061b;
  font-weight: 700;
  margin:auto;

  @media (max-width: 420px) {
    font-size: 10px;
    justify-content: start;
  }
  @media (max-width: 320px) {
    font-size: 7px;
    justify-content: start;
  }

`;

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
 
`;

const StatsBox = ({ title, value }: any) => {
  return (
    <Box>
      <Title>{title}:    
      <ValueWrapper>{value && <Value>{value}</Value>}</ValueWrapper>
      
      </Title>
      
    </Box>
  );
};

export default StatsBox;