import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import GlobalStyle from './style/global';
import styled from 'styled-components';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { AppRouter } from 'components/AppRouter';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;


ReactDOM.render(
  <BrowserRouter>
    <Wrapper>
      <GlobalStyle />
      <AppRouter />
    </Wrapper>
  </BrowserRouter>,
  document.getElementById('root')
);
